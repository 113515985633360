<template src="./Afisha_new.html"></template>

<script>
// import afisha from '@/assets/json/afisha.json'
// import pushkinsCardEvents from '@/assets/json/pushkinsCardEvents.json'
import BuyTicket from '@/components/buy-ticket';
export default {
  name: "Afisha",

  components: { BuyTicket },

  data () {
    return {
        afishaList:{},
        pushkinList:[],
        newAfishaList:[],
        eventNames:[],
        selectEventData:'',
        selectEvent:'',
        pageReady:false,
        sheet: false,
        eventDataSelect:false,
        showSelectDetail:false,
        showMoreDetail:{
            status:false,
            data:''
        }
    }
  },

      async created(){
      this.loadAfisha();
      this.convertPushka();
    },
  methods:{
    // информация о мероприятии
    consoleShow(){
        console.log(this.afishaList)
        console.log(this.afishaList.data)
        console.log(this.afishaList.data.events)
    },
    uniqueEvents(){
      let result = [];
      let array = this.eventNames;
      for (let str of array) {
        if (!result.includes(str)) {
          result.push(str);
        }
      }
      this.eventNames = result;
    },
    indexer(word){
      let str = word.replace(/[её]/g, "(е|ё)"); // Но скобки то экранируются! Что делать?
      return str;
    },
    timestampConvert(timestamp){
      var d = new Date(timestamp), // Convert the passed timestamp to milliseconds
      yyyy = d.getFullYear(),
      mm = ('0' + (d.getMonth() + 1)).slice(-2),  // Months are zero based. Add leading 0.
      dd = ('0' + d.getDate()).slice(-2),         // Add leading 0.
      hh = d.getHours(),
      h = hh,
      min = ('0' + d.getMinutes()).slice(-2),     // Add leading 0.
      time;
      // ie: 2014-03-24, 3:00 PM
      time = yyyy + '-' + mm + '-' + dd + ' ' + h + ':' + min + ':00';
      return time;
    },

    convertAfisha(data){
    data.events.forEach((item)=>{this.eventNames.push(item.name);});
    //
    this.uniqueEvents();
    //
    for (let str of this.eventNames) {
    let result = data.events.filter(el=>el.name === str);
    // name// show_id// lay_id// price// show_culture_id// show_age// show_tag// dates
    this.newAfishaList.push({name:str,details:result,show_age:result[0].show_age})
    }
    this.AfishaModifer();
    },
    loadAfisha: function() {
    let pr2 = this.$store.state.api.loadAfisha();
    pr2.then( data =>{
    this.afishaList.data = data;
    this.convertAfisha(data);
    })
    },
    convertPushka(){
    let pr2 = this.$store.state.api.loadAfishaCulture();
    pr2.then( data =>{
    data.data.events.forEach((item)=>{
      item.places.forEach((place)=>{
          place.seances.forEach((seance)=>{
              seance.startLocal = seance.startLocal.replace("T", " ");
          })
      });
      this.pushkinList.push(item)
    })
    })
    },
    AfishaModifer(){
        let pr2 = this.$store.state.api.loadAfishaCulture();
        pr2.then( data =>{
        // console.log("AfishaCulture");
        // console.log(data);
        data.data.events.forEach((event)=>{
          event.places.forEach((place)=>{
              place.seances.forEach((seance)=>{
                  // seance.startLocal = this.timestampConvert(seance.startLocal);
                  seance.startLocal = seance.startLocal.replace("T", " ");
              })
          });
          this.newAfishaList.forEach((item)=>{
              //разворачиваем все е/ё наизнанку, что бы сделать их одинаковыми
              let word1 = this.indexer(event.name);
              let word2 = this.indexer(item.name);
              let new_result = word1.indexOf(word2);
              if(new_result !== -1){
                  //нахождения уникальных событий afisha и добавление в них данных из pushkinsCardEvents
                  item._id = event._id;
                  item.description = event.description;
                  item.shortDescription = event.shortDescription;
                  item.tags = event.tags;
                  item.image = event.image;
                  item.image_l = event.image.name;
                  item.category = event.category;
                  item.isFree = event.isFree;
                  item.price = event.price;
                  item.maxPrice = event.maxPrice;
                  item.saleLink = event.saleLink;
                  item.additionalSaleLinks = event.additionalSaleLinks;
                  item.extraFields = event.extraFields;
                  item.places = event.places;
                  item.isPushkinsCard = event.isPushkinsCard;
                  item.paymentTerminals = event.paymentTerminals;
                  item.ticketSystems = event.ticketSystems;
                  item.needCheck = event.needCheck;
                  item.premoderationErrors = event.premoderationErrors;
                  item.gallery = event.gallery;
                  item.organization = event.organization;
                  item.status = event.status;
                  item.updateDate = event.updateDate;
                  item.author = event.author;
                  item.seances = event.seances;
                  item.start = event.start;
                  item.end = event.end;
                  item.createDate = event.createDate;
                  item.altName = event.altName;
                  item.chat = event.chat;
                  item.inAccepted = event.inAccepted;
                  item.acceptDate = event.acceptDate;
                  item.isPublished = event.isPublished;

                  event.seances.forEach((timeEvent)=>{
                      let timeItem = this.timestampConvert(timeEvent.start);
                      item.details.forEach((ItemTimeEvent)=>{
                          if(timeItem === ItemTimeEvent.timedate){
                              // нахождение временных совпадений двух из двух файлов.
                              ItemTimeEvent.date.placeIndex = timeEvent.placeIndex;
                              ItemTimeEvent.date.start = timeEvent.start;
                              ItemTimeEvent.date.end = timeEvent.end;
                          }
                      });
                  });
                  // console.log('------------')
              }
          })
          this.pushkinList.push(event)

        })
        this.pageReady = true;
    })

    },
    selectEventBtn(event_name,event_id){
        this.sheet = !this.sheet;
        console.log(event_name,event_id);
        let foundEvent = this.newAfishaList.find(el=>el.name === event_name);
        this.selectEvent = {
            name:event_name,
            _id:event_id,
            details:foundEvent.details,
            additionalSaleLinks:foundEvent.additionalSaleLinks
        }
        console.log(this.selectEvent);
    },
    selectEventDataBtn(event_name,event_id){
        this.eventDataSelect = true;
        let foundEvent = this.newAfishaList.find(el=>el.name === event_name);
        let foundEventDate = foundEvent.details.find(ev=>ev.id === event_id);
        // Тестирование расшифровки unix времени, на базе выбранного события
        // foundEvent.seances.forEach((item)=>{
        //     item.convertTime = this.timestampConvert(item.start);
        // })
        // foundEvent.places[0].seances.forEach((item)=>{
        //     item.convertTime = this.timestampConvert(item.start);
        // })
        console.log(foundEventDate);
        this.selectEventData = {
            id:foundEventDate.id,
            name:event_name,
            date_h:foundEventDate.date.date_h,
            show_id:foundEventDate.show_id,
            lay_id:foundEventDate.lay_id,
            prices:foundEventDate.prices,
            show_culture_id:foundEventDate.show_culture_id,
            show_age:foundEventDate.show_age,
            show_tag:foundEventDate.show_tag,
            timedate:foundEventDate.timedate,
            places:{
              _id:foundEvent.places[0]._id,
              start:foundEventDate.date.start,
              placeIndex:foundEventDate.date.placeIndex
            },
            show_time:foundEventDate.show_time
        };
    },
    showMoreText(Event_ID){
        let foundEvent = this.newAfishaList.find(el=>el._id = Event_ID)
        this.showMoreDetail.status = true;
        this.showMoreDetail.data = foundEvent;
        console.log(foundEvent.showMoreText);
    }
  },
}
</script>

<style scoped>
    @import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
    @import url('https://cdn.jsdelivr.net/npm/@mdi/font@4.x/css/materialdesignicons.min.css');
    #app {
        font-family: Avenir, Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-align: center;
        color: #2c3e50;
        margin-top: 60px;
    }
    .AfishaTimeBtn{
        margin: 5px 0;
    }
    .event-name{
        padding: 10px 0;
        border-bottom:2px solid #000;
    }
    .event-shortDesc{
        min-height: 64px;
    }
    .eventDates{
        padding: 15px 0 10px;
    }
    .eventDatesList{
        margin: 15px 0;
    }
</style>
